@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..90&display=swap');

* {
  outline: none;
}


ul, ol {
  list-style: none;
  margin:0;
  padding: 0;
}

.frontend h1, 
.frontend h2, 
.frontend h3, 
.frontend h4, 
.frontend h5, 
.frontend h6,
.frontend p {
  margin: 0;
}
.frontend h1,
.modalContent h1, 
.frontend h2, 
.modalContent h2,
.frontend h3, 
.modalContent h3,
.frontend h4,
.modalContent h4,
.frontend h5,
.modalContent h5,
.frontend h6,
.modalContent h6 {
  color: var(--text-color);
  line-height: 1.15;
  margin: 0;
  font-weight: 500;
}
.frontend h1,
.modalContent h1 {
  font-size: 1.8em;
}
.frontend h2,
.modalContent h2 {
  font-size: 1.6em;
  font-weight: 600;
}
.frontend h3,
.modalContent h3 {
  font-size: 1.4em;
}
.frontend h4,
.modalContent h4 {
  font-size: 1.134em;
}
.frontend h5,
.modalContent h5 {
  font-size: 1em;
}
.frontend h6,
.modalContent h6 {
  font-size: .8em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 600;
}


a:hover,
button:hover {
  cursor: pointer;
}

.loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--background-color);
}

.frontend {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100dvh;
  width: 100vw;
  font-family: 'Inter', sans-serif;
}
.wrapper {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  scroll-behavior: smooth;
  z-index: 1;
}
.backgroundImage {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  opacity: .2;
  background-repeat: no-repeat;
  background-position: center;
}
header,
footer,
section,
nav {
  display: flex;
}
.container {
  max-width: 100vw;
  width: 100%;
  padding: 0 96px;
}
img {
  max-width: 100%;
  display: block;
}

.btn {
  padding: 12px 24px;
  border-radius: 6px;
  border: none;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: white;
}
.primary {
  background-color: var(--restaurantColor);
}
.secondary {
  background-color: #eee;
  color: black;
}
.delete {
  background-color: transparent;
  color: var(--red);
}

/* Header */

header {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  z-index: 99;
}
header .container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.logo {
  width: 160px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

header .right {
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.websiteUrl {
  color: var(--restaurantColor);
  border: 2px solid;
  text-decoration: none;
  border-radius: 24px;
  height: 42px;
}




.mainCategoriesSwitcher {
  display: flex;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 72px;
  z-index: 11;
  flex-direction: row;
  border-radius: 16px;
  width: fit-content;
  border-radius: 8px;
  overflow: hidden;

}
.mainCategoryLink {
  overflow: hidden;
}

.mainCategoryButton {
  text-transform: uppercase;
  font-weight: 600;
  padding: 12px 16px;
  font-size: 16px;
  background: transparent;
  line-height: 1;
  color: var(--restaurantColor);
  cursor: pointer !important;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
  gap: 6px;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}

.mainCategoryLink.active {
  pointer-events: none; 
}

.subCategoriesNav {
  position: sticky;
  top: 0;
  width: 100vw;
  z-index: 1000;
  justify-content: center;
  padding: 0;
  transition: all .3s ease-in-out;
}
.subCategoriesNav:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--restaurantColor);
  opacity: .2;
  transition: all .3s ease-in-out;
}

.subCategoriesNav.sticked {
  background-color: var(--background-color);
}
.react-horizontal-scrolling-menu--wrapper {
  margin-left: -24px;
  margin-right: -24px;
}



.subCategoriesLinks {
  flex-direction: row !important;
  scrollbar-width: none;
}
.subCategoriesLinks::-webkit-scrollbar {
  display: none;
}
.subCategoriesLink {
  color: var(--text-color) !important;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  padding: 12px;
  white-space: nowrap;
  cursor: pointer !important;
  line-height: 1;
  text-transform: capitalize;
  overflow: hidden; 
  line-height: 1.5;
  opacity: .7;
  transition: var(--transition);
  box-shadow: 0px -2px 0 0px inset transparent;

}
.subCategoriesLink:hover {
  opacity: 1;
  color: var(--restaurantColor) !important;
}
.subCategoriesLink.active {
  opacity: 1;
  color: var(--restaurantColor) !important;
  box-shadow: 0px -2px 0 0px inset var(--restaurantColor);
}
.mainCategoryLink:before{
  content: '';
  background-color: var(--restaurantColor);
  opacity: .2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all .3s ease;
}

.mainCategoryLink:hover:before {
  opacity: .4;
}

.mainCategoryLink::after {
  background: rgba(0,0,0,.6);
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  content: '';
}

.mainCategoryLink.active:before {
  opacity: 1;
}
.mainCategoryLink.active .mainCategoryButton {
  color: rgba(0, 0, 0, 0.6);
}

nav::-webkit-scrollbar {
  display: none;
}

.container,
.container * {
  display: flex;
  flex-direction: column;
}

.filtersWrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 48px;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 24px;
  margin-left: -24px;
  margin-right: -24px;
  scrollbar-width: none;
}
.filtersWrapper::-webkit-scrollbar {
  display: none;
}

.filtersWrapper .button {
  font-size: 14px;
  opacity: .76;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: white;
}
.filters {
  flex-direction: row;
  gap: 8px;
}
.filters .filter {
  padding: 4px 12px !important;
  color: var(--restaurantColor);
  font-weight: 500;
  border-radius: 6px;
  text-transform: capitalize;
  background-color: transparent;
  border: none;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
}


.filters .filter span {
  z-index: 1;
}
.filters .filter span path {
  fill: white;
}
.filters .filter.active {
  color: rgba(0, 0, 0, 0.6);
}

.filters .filter::before {
  background: var(--restaurantColor);
  opacity: .2;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  content: '';
  transition: var(--transition);
}
.filters .filter:hover::before {
  opacity: .3;
}

.filters .filter.active::before {
  opacity: 1;
}


.categories {
  display: flex;
  gap: 96px;
  padding: 48px 0 96px;
}
.category {
  gap: 32px;
}
.category h2 {
    color: var(--restaurantColor);
    font-size: 20px;
    letter-spacing: .03em;
    text-transform: uppercase;
    flex-direction: row;
    gap: 16px;
    align-items: center;
}
.categoryPrice {
  color: var(--text-color);
  font-weight: 500;
  font-size: 18px;
  flex-direction: row;
}
.items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 0px;
  column-gap: 48px;
}

.item {
  flex-direction: row;
  gap: 24px;
  background: transparent;
  align-items: flex-start;
  transition: var(--transition);
  position: relative;
  padding: 16px 0;
  cursor: pointer !important;
  margin-top: -2px;
}


.item:before,
.item:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(45deg, transparent, var(--restaurantColor), transparent);
}
.item:after {
  top: auto;
  bottom: 0;
}


.item:hover {
  cursor: pointer;
}

.itemImage {
  width: 120px;
  height: 120px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f1f1f1;
  z-index: 1;
  justify-content: center;
  align-items: center;
}
.itemImage img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition);
}
.item:hover .itemImage img {
  transform: scale(1.1);
}
@media (max-width: 767px) {
  .itemImage {
    height: 86px;
    width: 86px;
  }
}

.itemInfo {
  gap: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  z-index: 1;
  transition: var(--transition);

}
@media (min-width:960px) {
.item .itemInfo:hover {
  transform: scale(1.02);
}
}


.itemInfo > .head {
  flex-direction: column;
  gap: 6px;
}
.itemInfo > .head .itemName {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
}
@media (min-width: 960px) {
.itemInfo > .head .itemName h4 {
  width: 80%;
}
}
.price {
  color: var(--restaurantColor);
  font-size: 16px;
  font-weight: 600;
}
.variations {
  display: flex;
  flex-direction: row;
}
.variation {
  gap: 0px;
  align-items: flex-start;
  padding: 0 8px;
  flex-direction: column;
  display: flex;
}
.variations .variation:not(:last-of-type) {
  border-right: 1px solid rgba(255,255,255,.2);
}
.variations .variation:first-child {
  padding-left: 0;
}
.variations .variation:last-child {
  padding-right: 0;
}
.modalContent .variations {
  flex-direction: column;
  width: 100%;
}
.modalContent .variation {
  flex-direction: row;
  justify-content: space-between;
  gap: 0;
  align-items: center;
  padding: 4px 0;
  border-right: 0 !important;
}
.variationName {
  font-size: 14px;
  color: var(--text-color);
}


.categories .item .itemInfo .head > p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
  max-width: 85%;
  opacity: .76;
  color: var(--text-color);
}


/* cards style */


.items.cards {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 32px;
}
.cards .item {
  flex-direction: column;
  padding: 0;
  border: none;
  width: 260px;
  min-width: 220px;
  border-radius: 10px;
  overflow: hidden;
}
.cards .itemImage {
  width: 100%;
  padding-top: 130%;
}
.cards .item .itemInfo {
  position: absolute;
  z-index: 1;
  bottom: 0;
  height: fit-content;
  padding: 24px;
  width: 100%;
  background: linear-gradient(0, var(--background-color), transparent);
  gap: 6px;
  z-index: 2;
}
.cards .item .itemInfo .itemName h4 {
  width: 100%;
}
.itemImage .unavailable {
  position: absolute;
  top: 16px;
  left: 24px;
}


.subCategoriesNav::-webkit-scrollbar,
.subCategoriesNav::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
}


@media (max-width:960px) {
  .items.cards {
    margin-left: -24px;
    margin-right: -24px;
    width: 100vw;
    overflow-x: auto;
    gap: 24px;
    scrollbar-width: none;
  }
  .items.cards .item:first-of-type {
    margin-left: 24px;
  }
  .items.cards .item:last-of-type {
    margin-right: 24px;
  }
}


.priceRow {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}
.modalContent .priceRow {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

@media (max-width: 1280px) {
  .container {
    padding: 0 48px;
  }
  .items {
    row-gap: 0px;
    column-gap: 48px;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 960px) {
  .container {
    padding: 0 24px;
  }
  .categories {
    gap: 48px;
  }
  .items {
    row-gap: 0px;
    column-gap: 0px;
    grid-template-columns: 1fr;
  }
}


.modal {
  display: flex
}

.modalContent {
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid rgba(255,255,255,.1);
  border-radius: 16px;
  flex-direction: column;
  width: 100%;
  max-height: 98vh;
  position: relative;
  max-width: 480px;
  overflow-y: auto;
  display: flex;
}
.modalContent h6 {
  color: var(--text-color);
}
.modalHeader {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  padding: 16px;
  z-index: 3;
  position: sticky;
  top: 0;
  background-color: transparent;
  transition: background-color .24s ease;
}

.modalHeader .itemName h4 {
  color: white;
}


.close {
  font-size: 24px;
  font-weight: 400;
  background: var(--background-color);
  color: var(--text-color);
  height: 32px;
  width: 32px;
  text-align: center;
  border-radius: 32px;
  z-index: 10;
  line-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
  opacity: .85;
}
.close path {
  fill: var(--text-color);
}

.close:hover,
.close:focus {
  opacity: 1;
}

.modalContent .itemImage {
  max-width: initial;
  width: 100%;
  padding-top: 75%;
  height: 0;
  border-radius: 0;
  margin-top: -64px;
}
.modalContent .itemImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  background: linear-gradient(0, transparent, #121212);
  z-index: 1;
  opacity: .5;
}


.modalContent .itemImage .unavailable {
  position: absolute;
  top: auto;
  left: 24px;
  bottom: 16px;
  transform: scale(1.1);
  transform-origin: bottom left;
}

.modalContent .itemInfo {
  padding: 16px 32px 32px;
  gap: 24px;
  justify-content: flex-start;
}
.modalContent .itemImage + .itemInfo {
  padding: 32px;
}

.modalContent .itemInfo > .head {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.modalContent  .itemInfo > .head .itemName {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.modalContent .itemInfo .head p {
  max-width: 80%;
  opacity: .76;
  color: var(--text-color);
}


.buttons {
  flex-direction: row;
  gap: 4px;
}
.tags {
  flex-direction: row;
  gap: 4px;
  display: flex;
}
.tag,
.note {
  font-weight: 500;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 16px;
  border-radius: 6px;
  width: fit-content;
  text-transform: capitalize;
  letter-spacing: 0;
  height: fit-content;
  color: var(--restaurantColor);
  backdrop-filter: blur(5px);
  overflow: hidden;
}

.tag::before,
.note::before {
  background: var(--restaurantColor);
  opacity: .2;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: '';
}

.tag.unavailable {
  color: var(--text-color);
  background-color: var(--red);
  font-weight: 500;
  font-size: 13px;
}

.separator {
  width: 100%;
  height: 1px;
  background: linear-gradient(45deg, rgba(255,255,255,.1), transparent);
}

.modalBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.modalBlockTitle {
    font-size: .8em;
    letter-spacing: 0.1em;
    color: var(--text-color);
    line-height: 1.15;
    margin: 0;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.06em;
}
.allergenesList {
  flex-direction: row !important;
  flex-wrap: wrap;
  gap: 6px;
  display: flex;
}
.allergenes p {
  font-size: 14px;
}
.allergene {
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(255,255,255,.1);
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 13px;
  align-items: center;
  gap: 6px;
  text-transform: capitalize;
}
.allergene .icon {
  width: 20px;
}
.languageSwitcher {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.languageSwitcher li {
  color: var(--restaurantColor);
  height: 32px;
  width: 32px !important;
  border-radius: 32px;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  font-weight: 500;
  padding: 0 !important;
  background-color: var(--background-color);
  text-transform: uppercase;
  overflow: hidden;
  border: 1px solid #FFF;
  opacity: .35;
  cursor: pointer;
  transition: all .3s ease;
}
.languageSwitcher li img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.languageSwitcher li:hover {
  opacity: .76;
}
.languageSwitcher li.active {
  opacity: 1;
}


@media (max-width: 767px) {
  .modal {
    margin-top: 24px;    
  }
  .modalContent {
    height: -webkit-fill-available;
    max-width: 100vw;
    border-radius: 16px 16px 0 0;    
  }
}





/* CART */

.cartModal {
  width: 420px;
  padding: 1vh;
  height: 100%;
  position: relative;
}
.cartModalContent {
  background-color: var(--background-color);
  height: 100%;
  padding: 24px;
  border-radius: 12px;
  position: relative;
}
.cartList {
  display: flex;
  flex-direction: column;
}
.cartItem {
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.cart .itemName {
  font-size: 16px;
  font-weight: 500;
}

.qty {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.qty button {
  height: 24px;
  width: 24px;
  border-radius: 24px;
  padding: 0;
  background-color: transparent;
  border: 2px solid;
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.cartActions {
  width: 100%;
  display: flex;
}
.cartActions button {
  flex: 1;
}



/* ChECkout */


.checkoutModalContent {
  width: 100%;
  background-color: white;
}