@import url(Normalize.css);

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

:root {
  --brand: #f25c54;
  --brand100: #FDDDDC;
  --brand200: #FBC3C1;
  --brand300: #F9AAA6;
  --brand600: #D95851;
  --backgroundColor: #F6F9FC;
  --heading: #292D36;
  --text: #445b78;
  --transition: all 0.35s ease;
  --error50: #FEF3F2;
  --error: #ff0746;
  --error700: #B42318;
  --warning: #FFBA2F;
  --warning50: #FFF1D5;
  --success: #32D583;
  --success50: #ECFDF3;
  --success700: #027A48;
  --borders: #e5e5e9;
  --grey50: #f9fafa;
  --grey100: #F5F7FA;
  --grey300: #A6B1C2;
  --grey400: #98A2B3;
  --grey500: #667085;
  --grey600: #373C48;
  --grey700: #292D36;
  --blue50: #EFF4FF;
  --blue600: #155EEF;
  --padding-xl: 96px;
  --padding-lg: calc(var(--padding-xl) / 2);
  --padding-md: calc(var(--padding-xl) / 3);
  --padding-sm: calc(var(--padding-xl) / 4);
  --padding-xs: calc(var(--padding-xl) / 6);
  --padding-xxs: calc(var(--padding-xl) / 12);
  --radius-xl: calc(var(--radius-xs) * 5);
  --radius-lg: calc(var(--radius-xs) * 4);
  --radius-md: calc(var(--radius-xs) * 3);
  --radius-sm: calc(var(--radius-xs) * 2);
  --radius-xs: 4px;
  --transition: all 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --transition-slow: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --box-shadow: 0 7px 12px 0 rgba(0,0,0,.2);
}
.Toastify__toast-container {
  max-width: 80% !important;
  text-align: center !important; 
  font-size: 16px;
  z-index: 9999999999 !important;
}
.Toastify__toast-container:not(.loginToast) {
  width: 870px !important;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: var(--success) !important;
  padding: var(--padding-xs);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: var(--error) !important;
  padding: var(--padding-xs);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  background-color: #FEFBE8 !important;
  padding: var(--padding-xxs);
  border-left: 2px solid #FAC515;
  color: #212122 !important;
  font-weight: 500 !important;
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.Toastify__close-button {
  align-self: center !important;
}

* {
  -webkit-appearance: none;
  appearance: none;
  box-sizing: border-box;
  position: relative;
}
#root {
  min-height: 100vh;
  z-index: 0;
}

html {
  height: 100%;
}
body,
html {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  background: var(--background-color);
  color: var(--backend-text-color);
  width: 100%;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

body.loading {
  background: var(--background-color) !important;
}

@media (max-width:767px) {
  body {
    font-size: 14px;
  }
  .mobile-hidden {
    display: none !important;
  }
}

.no-scroll {
  overflow: hidden;
  height: 100%;
}

.chakra-input__right-element button {
  font-size: 12px !important;
  margin-right: 16px;
}
.chakra-input__left-addon {
  line-height: var(--input-height);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
h1, h2, h3, h4, h5, h6 {
  color: #121212;
  line-height: 1.15;
  margin: 0;
  font-weight: 500;
}
p {
  color: var(--text);
}

 a,
 a:hover,
 .text-link:hover {
  color: var(--brand500);
}
 h1 {
  font-size: 2.25em;
  text-transform: capitalize;
}
 h2 {
  font-size: 2em;
}
h3 {
  font-size: 1.75em;
}
h4 {
  font-size: 1.5em;
}
h5 {
  font-size: 1.25em;
}
h6 {
  font-size: 1em;
}
a,
button, 
label {
  cursor: pointer !important;
}
.tooltip {
  font-size: 12px;
  background-color: var(--grey400);
  color: white;
  height: 16px;
  width: 16px;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
}
header,
footer,
section,
nav {
  display: flex;
}

form {
  flex-direction: column;
  display: flex;
}

.row {
  flex-direction: row !important;
  gap: 8px;
  display: flex;
}
.spaceBetween {
  justify-content: space-between;
}

.field {
  flex-direction: column;
  display: flex;
  gap: 4px;
  width: 100%;
  padding: 4px 0;
}
.phoneInput > div {
  flex-direction: row;
  align-items: center;
}

label {
  font-weight: 500;
  font-size: 12px;
}

.container,
.container * {
  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1320px;
  width: 100%;
  padding: 0 24px;
}
.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
}



img {
  max-width: 100%;
}

button,
.btn {
  padding: 12px 24px;
  border-radius: 6px;
  border: none;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: white;
  transition: var(--transition);
  height: fit-content;
  flex-direction: row !important;
  gap: var(--padding-xxs);
  cursor: pointer;
}

.primary {
  background-color: var(--restaurantColor) !important;
  color: white !important;
}
.secondary {
  background-color: #eee !important;
  color: black !important;
}

.paymentMethodOption input+label {
  width: 100%;
}
.paymentMethodOption input:checked+label {
  border: 2px solid #121212;
  box-shadow: none !important;
}

input, button,
.chakra-input__left-addon {
  font-size: 1em !important;
}
button.sm {
  font-size: 14px !important;
}

.timeOptions .option {
  border: 2px solid var(--borders);
  border-radius: 8px;
  cursor: pointer;
  transition: all .3s ease;
}
.timeOptions .option > * {
  opacity: .5;
}
.timeOptions .option.active {
  border-color: #121212;
  opacity: 1;
}
.timeOptions .option.active > * {
  opacity: 1;
}

/* LOGIN PAGE */

.loginContainer {
  max-width: 360px;
  width: 100%;
  padding: 0 16px;
}
.guddenHeader {
  background-color: #121212;
  width: 100%;
  padding: 0px 64px;
  height: 64px;
  justify-content: flex-start;
}
.guddenLogo {
  height: 40px;
  width: 40px;
}

.tag {
  font-weight: 500;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 6px;
  width: fit-content;
}
  
.error {
  background-color: var(--error50);
  color: var(--error) !important;
}
.success {
  color: var(--success);
  background-color: var(--success50) !important;
}







.timeOptions option {
  border: 2px solid var(--grey300);
  width: 100%;
}

.separator {
  height: 1px;
  background-color: var(--borders);
  width: 100%;
}

.stripeOption {
  gap: 0px;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
  border: 2px solid var(--borders)
}
/* Spinner */
.spinnerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.975);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  gap:16px
}

.loadingSpinner {
  border: 5px solid #f3f3f3; 
  border-top: 5px solid var(--restaurantColor); 
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}





.ReactModal__Overlay .ReactModal__Content {
  opacity: 0;
  transition: all 250ms ease-in-out;
  transform: translateY(100px);
}

.ReactModal__Overlay--after-open .ReactModal__Content {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close .ReactModal__Content {
  opacity: 0;
  transform: translateY(30px);
}

.chakra-checkbox__label,
.chakra-radio__label {
  flex: 1;
  display: flex;
  justify-content: space-between;
  font-size: 14px !important;
  font-weight: 400;
}



.modalContent {
  background-color: white;
  color: #121212;
  border-radius: 16px;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  max-height: 98%;
  position: relative;
  max-width: 480px;
  display: flex;
}

.modalInner {
  max-height: calc(100% - 94px);
  overflow-y: auto;
  flex: 1;
}
.modalInner::-webkit-scrollbar {
  display: none;
}
.modalHeader {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  padding: 16px;
  z-index: 3;
  position: sticky;
  top: 0;
  background-color: transparent;
  transition: background-color .24s ease;
}
.modalHeader.scrolled {
  background-color: white;
  border-bottom: 1px solid #F2F2F2;
}

.close {
  font-size: 24px;
  font-weight: 400;
  background: white;
  color: var(--restaurantColor);
  height: 32px;
  width: 32px;
  text-align: center;
  border-radius: 32px;
  z-index: 10;
  line-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
  opacity: .85;
  border: 1px solid #F2F2F2;
}
.close path {
  fill: var(--restaurantColor);
}

.close:hover,
.close:focus {
  opacity: 1;
}

.chakra-accordion__button {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.closeIcon:hover {
  stroke: white;
}

.react-tel-input {
  margin-top: 0 !important;
}
.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255,.24) !important;
  border-left: 0 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
}


@keyframes priceShow {
  0% {
      opacity: 0;
      transform: translateY(-80%)
  }

  to {
      opacity: 1;
      transform: translateY(0)
  }
}
.priceAnimation {
  animation: priceShow 0.5s ease-out;
}
@keyframes gradient {
  0% {
      background-position: 0% 50%
  }

  50% {
      background-position: 100% 50%
  }

  100% {
      background-position: 0% 50%
  }
}
.bg {
  animation: gradient 5s ease infinite;
}

.imageAnimation {
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.5s ease-out;
}
.imageAnimation.startAnimation {
  opacity: 1;
  transform: translateY(0);
}





#contactForm input:focus-visible,
#contactForm textarea:focus-visible {
  border: 1px solid var(--brand) !important;
  box-shadow: 0 0 0 1px var(--brand);
}

.imageSlider {
  width: 100%;
  max-width: 612px;
  position: sticky !important;
  top: 48px;
  transition: all .2s ease-in;
  
}
@media (min-width: 1281px) { 
  .imageSlider {
  transform: scale(1.3);
  transform-origin: top right;
  }
}
@media (max-width: 960px) {
.imageSlider {
  position:  relative !important;
  top: 0;
}
}


.module {
  cursor: pointer;
}
.moduleBtn::before {
  content: "";
  position: absolute;
  top: -8px;
  left: -8px;
  right: -8px;
  bottom: -8px;
  background-color: transparent;
  border: 0px solid;
  border-radius: 16px;
  opacity: 1;
  transform: scale(0);
  transition: all 0s ease;
}

.module:hover .moduleBtn::before {
  border: 3px solid;
  transform: scale(1);
  transition: all .4s ease;
}


/* settings */
.tabLinks {
  max-width: 100vw;
  scrollbar-width: none;
  overflow-x: auto;
}
.tabLinks .tabLink {
  cursor: pointer;
  justify-content: flex-end;
  color: var(--grey500);
  font-weight: 500;
  padding: 8px 12px !important;
  gap: 8px;
  white-space: nowrap;
}

.tabLinks .tabLink:hover {
  color: var(--grey700);
}

.tabLinks .tabLink.active,
.tabLinks .tabLink.active:hover,
.tabLinks .tabLink:focus {
  color: var(--brand) !important;
}

.tabLinks .tabLink.active {
  font-weight: 500;
  border-bottom: 2px solid var(--brand)
}


.history-log .log .logIcon svg {
  background-color: white;
  box-shadow: 0 0 0 4px white;
}

.history-log .log .logIcon:before {
  content: '';
  width: 3px;
  background-color: var(--grey100);
  display: block;
  position: absolute;
  top: -8px;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}