/* NotFound.css */
.NotFoundContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: #f2f2f2;
  }
  .NotFoundContent {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }
  .NotFoundContent h1 {
    font-size: 2.5rem;
    color: #333;
  }
  
  .NotFoundContent p {
    font-size: 1rem;
    color: #666;
  }
  
  .HomeLink {
    padding: 10px 20px;
    background-color: var(--brand);
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    width: fit-content;
    margin-top: 24px;
  }

  .HomeLink:hover {
    background-color: var(--brand600);
  }
  