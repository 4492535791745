* {
  outline: none;
}
.anchor {
  scroll-margin-top: 80px;
}
ul, ol {
  list-style: none;
  margin:0;
  padding: 0;
}

.shop h1, 
.shop h2, 
.shop h3, 
.shop h4, 
.shop h5, 
.shop h6,
.shop p {
  margin: 0;
}
.shop h1, 
.shop h2, 
.shop h3, 
.shop h4, 
.shop h5, 
.shop h6 {
  color: #121212;
  line-height: 1.15;
  margin: 0;
  font-weight: 600;
}
.shop h1 {
  font-size: 2em;
}
.shop h2 {
  font-size: 1.75em;
}
.shop h3 {
  font-size: 1.5em;
}
.shop h4 {
  font-size: 1.15em;
}
.shop h5 {
  font-size: 1em;
}
.shop h6 {
  font-size: .8em;
}


a:hover,
button:hover {
  cursor: pointer;
}
.shop {
  display: flex;
  flex-direction: column;
  width: 100%;
  width: 100vw;
}
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  scroll-behavior: smooth;
}
.backgroundImage {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  opacity: .2;
  background-repeat: no-repeat;
  background-position: center;
}
header,
footer,
section,
nav {
  display: flex;
}
.container {
  max-width: 1920px;
  width: 100%;
  padding: 0px 48px;
  gap: 48px;
}
img {
  max-width: 100%;
  display: block;
}

.btn {
  padding: 12px 24px;
  border-radius: 6px;
  border: none;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: white;
}
.primary {
  background-color: var(--restaurantColor);
}
.secondary {
  background-color: #eee;
  color: black;
}
.delete {
  background-color: transparent;
  color: var(--red);
}



/* Header */

.shopHeader {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0;
  padding: 16px 0;
  background-color: white;
}
header .container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo img {
  height: 80px;
}



.subCategoriesNav {
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100vw;
  justify-content: center;
  overflow-x: auto;
  scrollbar-width: none;
  box-shadow: 0px -2px 0 0px inset var(--borders);
  background-color: white;

}
.subCategoriesNav .container {
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.subCategoriesLink {
  color: var(--grey500);
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  padding: 12px;
  white-space: nowrap;
  cursor: pointer;
  line-height: 1.5;
  text-transform: capitalize;
  overflow: hidden;  
}
.subCategoriesLink.active {
  color: var(--restaurantColor);
  box-shadow: 0px -2px 0 0px inset var(--restaurantColor);
}


nav::-webkit-scrollbar {
  display: none;
}

.container {
  display: flex;
  flex-direction: column;
}
.sectionMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 48px 0;
  background-color: var(--grey100);
}

.filtersWrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 24px;
  margin-left: -24px;
  margin-right: -24px;
  scrollbar-width: none;
}
.filtersWrapper::-webkit-scrollbar {
  display: none;
}
.filtersWrapper .button {
  font-size: 14px;
  opacity: .76;
  flex-direction: row;
  align-items: center;
}
.filtersWrapper .button,
.filtersWrapper .button svg path {
  color: #121212;
  fill:  #121212;
}
.filters {
  flex-direction: row;
}
.filters .filter {
  padding: 4px 12px !important;
  color: var(--restaurantColor);
  font-weight: 500;
  border-radius: 6px;
  text-transform: capitalize;
  background-color: transparent;
  border: none;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  white-space: nowrap;
}
.filters .filter span {
  z-index: 1;
}
.filters .filter.active span {
  color: var(--background-color);
}

.filters .filter::before {
  background: var(--restaurantColor);
  opacity: .2;
  border-radius: 8px;
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  content: '';
}
.filters .filter.active::before {
  opacity: 1;
}



.categories {
  display: flex;
  flex: 1;
}
.category h2 {
    color: var(--restaurantColor);
    font-size: 17px;
    text-transform: capitalize;
}
.categoryPrice {
  color: #121212;
  font-weight: 500;
  font-size: 18px;
  flex-direction: row;
}




.items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 24px;
  column-gap: 24px;
}

.item {
  flex-direction: row;
  gap: 24px;
  background: transparent;
  align-items: flex-start;
  transition: var(--transition);
  position: relative;
  padding: 12px;
  cursor: pointer !important;
  margin-top: -2px;
  box-shadow: 0 1px 4px #00000014;
  border-radius: 12px;
  background-color: white;
  line-height: 1;
}

.item:hover {
  cursor: pointer;
}

.itemImage {
  width: 86px;
  height: 86px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  background-color: #f1f1f1;
  z-index: 1;
  justify-content: center;
  align-items: center;
}
.itemImage img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition);
}
.item:hover .itemImage img {
  transform: scale(1.1);
}
.itemInfo {
  gap: 6px;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 1;
}

.itemName {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 500;
  gap: 4px;
}
.itemName span {
  font-style: italic;
  font-weight: 400;
}
.itemDescription {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  word-break: break-word;
  max-width: 90%;
  opacity: .76;
  color: #445B78;
  font-size: 14px;
  line-height: 19px;
  min-height: 38px;
}

.itemPrice {
  color: var(--restaurantColor);
  font-size: 14px;
  font-weight: 500;
}
.options {
  display: flex;
  flex-direction: column !important;
}
.optionsList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.option {
  gap: 0px;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0px;
  font-size: 14px;
  font-weight: 400;
  padding: 2px 4px;
  border-radius: 4px;
}
.option:hover {
  box-shadow: 0 0 0 3px rgba(23,84,88,.3);
}


.option > div {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.option input {
  --active: var(--restaurantColor);
  --border: #CCC;
  --border-hover: var(--grey500);
  --background: #fff;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  width: 20px !important;
  height: 20px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  border-radius: 7px;
}
.option input:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
    opacity: var(--o, 0);
}

.option input:checked {
  --b: var(--active);
  --bc: var(--active);
  --d-o: 0.3s;
  --d-t: 0.6s;
  --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
}

.option input:checked {
  --o: 1;
}
.option input[type='checkbox']::after {
    width: 4px;
    height: 8px;
    border: 2px solid white;
    border-top: 0;
    border-left: 0;
    left: 6.5px;
    top: 3px;
    transform: rotate(var(--r, 20deg));
}
.option input[type='checkbox']:checked {
  --r: 43deg;
}
.option input[type='radio'] {
  border-radius: 50%;
}
.option input[type='radio']:after {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
  opacity: 0;
  transform: scale(var(--s, 0.7));
}
.option input[type='radio']:checked {
  --s: 0.5;
}
.option input[type='radio']:checked:after {
  opacity: 1;
}


.optionName {
  font-size: 14px;
  color: #445B78;
  display: flex;
  align-items: center;
  gap: 8px;
}


.addToCart {
  width: 100%;
  align-items: center;
  gap: 16px !important;
  display: flex;
  background-color: white;
  padding: 24px;
  border-top: 1px solid var(--borders);
  z-index: 2;
}
.itemImage .unavailable {
  position: absolute;
  top: 16px;
  left: 24px;
}


.subCategoriesNav::-webkit-scrollbar,
.subCategoriesNav::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
}



.priceRow {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}


@media (max-width: 1550px) {
  .items {
    row-gap: 16px;
    column-gap: 16px;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 1280px) {
  .container {
    padding: 0px 48px;
  }
}
@media (max-width: 1190px) {
  .items {
    grid-template-columns: 1fr;
  }
  
}
@media (max-width: 960px) {
  .container {
    padding: 0px 24px;
  }
  .categories {
    gap: 48px;
  }
  .items {
    column-gap: 0px;
    grid-template-columns: 1fr;
    margin-left: -24px;
    margin-right: -24px;
    gap: 0;
  }
  .item {
    box-shadow: none;
    border-radius: 0;
    border-top: 1px solid var(--borders);
    border-bottom: 1px solid var(--borders);

  }
}

.modalContent {
  background-color: white;
  color: #121212;
  border-radius: 16px;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  max-height: 98%;
  position: relative;
  max-width: 480px;
  display: flex;
}

.modalInner {
  max-height: calc(100% - 94px);
  overflow-y: auto;
  flex: 1;
}
.modalInner::-webkit-scrollbar {
  display: none;
}
.modalHeader {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  padding: 16px;
  z-index: 3;
  position: sticky;
  top: 0;
  background-color: transparent;
  transition: background-color .24s ease;
}
.modalHeader.scrolled {
  background-color: white;
  border-bottom: 1px solid #F2F2F2;
}

.close {
  font-size: 24px;
  font-weight: 400;
  background: white;
  color: var(--restaurantColor);
  height: 32px;
  width: 32px;
  text-align: center;
  border-radius: 32px;
  z-index: 10;
  line-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
  opacity: .85;
  border: 1px solid #F2F2F2;
}
.close path {
  fill: var(--restaurantColor);
}

.close:hover,
.close:focus {
  opacity: 1;
}
.errorMessage {
  color: var(--error);
  text-align: center; 
  font-size: 14px; 
  max-width: 240px;
  align-self: center; 
}

.modalContent .itemImage {
  max-width: initial;
  width: 100%;
  padding-top: 75%;
  height: 0;
  border-radius: 0;
  margin-top: -64px;
}
.modalContent .itemInfo {
  padding: 16px 32px 32px;
  gap: 24px;
  justify-content: flex-start;
  flex: auto;
}

.modalContent .itemImage + .itemInfo {
  padding: 32px;
}
.modalContent .itemInfo > div {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
}
.modalContent .itemInfo .options {
  gap: 16px;
}

.modalContent .itemInfo h6 {
  display: flex;
  justify-content: space-between;
}
.modalContent .itemInfo h6 span {
  font-weight: 400;
}

.modalContent .itemInfo > .head {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.modalContent  .itemInfo > .head .itemName {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.modalContent .itemInfo p {
  max-width: 80%;
  opacity: .76;
  color: #445B78
}
.modalContent .itemInfo .price {
  font-size: 20px;
}

.shop .buttons {
  flex-direction: row;
  gap: 4px;
}
.tags {
  flex-direction: row;
  gap: 4px;
  display: flex;
}
.tag,
.note {
  font-weight: 500;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 16px;
  border-radius: 6px;
  width: fit-content;
  text-transform: capitalize;
  height: fit-content;
  color: var(--restaurantColor);
  backdrop-filter: blur(5px);
  overflow: hidden;
}

.tag::before,
.note::before {
  background: var(--restaurantColor);
  opacity: .2;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  content: '';
}

.tag.unavailable {
  color: var(--text-color);
  background-color: var(--red);
  font-weight: 500;
  font-size: 13px;
}

.separator {
  width: 100%;
  height: 1px;
  background: #F2F2F2;
}


.modalBlockTitle {
    font-size: .8em;
    color: var(--text-color);
    line-height: 1.15;
    margin: 0;
    font-weight: 600;
    text-transform: uppercase;
}
.allergenesList {
  flex-direction: row !important;
  flex-wrap: wrap;
  gap: 6px;
  display: flex;
}
.allergenes p {
  font-size: 14px;
}
.allergene {
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0,0,0,.1);
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 12px;
  align-items: center;
  gap: 6px;
  text-transform: capitalize;
}
.allergene .icon {
  width: 16px;
}

.languageSwitcher .wg-drop.country-selector {
  background-color: transparent;
}
.languageSwitcher button {
  color: var(--restaurantColor);
  height: 40px;
  width: 40px !important;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  font-weight: 500;
  padding: 0 !important;
  background-color: var(--background-color);
  text-transform: uppercase;
}
.languageSwitcher button.active {
  background-color: var(--restaurantColor);
  color: rgba(0, 0, 0, 0.6) !important;
}
.languageSwitcher div {
  background: transparent;
  position: absolute;
  border: none;
  top: 44px;
  display: flex;
  gap: 4px;
}
.languageSwitcher .country-selector .wg-li.focus a, 
.languageSwitcher .country-selector a:hover {
  color: #121212 !important;
  background-color: var(--restaurantColor)
}
.wg-list.country-selector li {
  margin: 0 !important;
}


@media (max-width: 767px) {
 
  .modalContent {
    height: 100vh;
    max-width: 100vw;
    border-radius: 16px 16px 0 0;    
  }
}


/* CART */

.cart {
  max-width: 380px;
  width: 100%;
  padding: 24px;
  background-color: white;
  border-radius: 12px;
  height: fit-content;
  position: sticky;
  top: 24px;
  box-shadow: 0 1px 4px #00000014;
  z-index: 1;

}
.cartContent {
  height: 100%;
  border-radius: 12px;
  position: relative;
  gap: 16px;
  display: flex;
  flex-direction: column;

}
.cartContent * {
  display: flex;
}
.cartList {
  display: flex;
  flex-direction: column;
}
.cartItem {
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  gap: 16px;
  border-bottom: 1px solid var(--borders);
}
.cartItem .itemImage {
  width: 52px;
  height: 52px;
  position: relative;
  padding-top: 0;
  margin-top: 0;
  border-radius: 4px;
}
.cartItem .itemDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
}
.cartItemTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.cartItemInfo {
  line-height: 1.2;
  font-size:12.8px;
  gap: 8px;
}
.cartButton {
  display: none;
  position: fixed;
  bottom: 16px;
  left: 16px;
  right: 16px;
  z-index: 99;
}

@media (max-width: 1024px) {
  .cart {
    display: none;
  }

  .cartButton {
    display: block;
  }
}

.extrasList {
  display: flex;
  flex-direction: row !important;
  align-items: baseline;
  gap: 4px;
  flex-flow: wrap;
  width: 100%;
}
.extra {
  white-space: nowrap;
  flex-direction: row;
  gap: 4px;
}

.cartFooter,
.cartFooter li {
  width: 100%;
}
.cartFooter {
  display: flex;
  flex-direction: column;
}
.cartFooter li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 6px 0;
  align-items: center;
  
}
.cartFooter li label,
.cartFooter li div {
  font-size: 15px;
  align-items: center;
}
.cartFooter li label {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.qty {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
}
.qty svg {
  opacity: .75;
  cursor: pointer;
}
.qty svg:hover {
  opacity: 1;
}

.modal .qty button.qtyBtn {
  height: 32px;
  width: 32px;
  font-size: 20px;
}
.modal .qty span {
  font-weight: 600;
  font-size: 16px;
}

.cartFooter .qty button.qtyBtn {
  height: 18px;
  width: 18px;
  font-size: 15px;
  line-height: 18px;
}
.cartActions {
  width: 100%;
  display: flex;
}
.cartActions button {
  flex: 1;
}
.cartActions button.inactive {
  opacity: .3;
  pointer-events: none;
}



/* ChECkout */


.checkoutColumn {
  background-color: white;
  border-radius: 8px;
  width: 66% !important;
}

.deliveryMode {
  display: flex;
  flex-direction: row;
  border-radius: 120px;
  overflow: hidden;
  background-color: var(--borders);
  padding: 4px;
  height: 40px;
}

.deliveryMode input+label {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  line-height: 16px;
  font-size: 14px;
  border-radius: 120px;
  color: var(--grey600);
  width: 84px;
  z-index: 2;
  transition: color .5s ease;
}
.deliveryMode input:checked+label {
  color: var(--restaurantColor);
}
.deliveryMode .activeCursor {
  position: absolute;
  background-color: white;
  top: 4px;
  height: calc(100% - 8px);
  z-index: 1;
  border-radius: 120px;
}

.paymentMethods .option {
  padding: 16px;
  align-items: flex-start;
  border: 2px solid var(--borders);
  border-radius: 8px;
  font-weight: 500;
}



.toolTip {
  font-size: 15px;
  background-color: white;
  color: #2e3333;
  max-width: 280px; 
  padding : 16px; 
  z-index: 99;
}

.checkoutSticky {
  position: sticky;
  top: 24px;
  gap: 16px;
}
.checkout .section {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  gap: 16px;
}

button {
  overflow: hidden;
}

.bgHover {
  background-color: rgba(0,0,0, .15);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: .25s all ease;
}
button:hover .bgHover {
  opacity: 1;
}